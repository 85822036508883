@import 'src/scss/globals';

.dashboard-sign-intro {
  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      gap: $gap-large;
    }
  }

  &__language {
    width: fit-content;
  }

  &__file {
    $file-border-style: 1px solid var(--colorBrandBackgroundInvertedHover);
    display: flex;
    flex-direction: column;
    width: 247px;
    align-items: center;
    border: $file-border-style;
    border-radius: 4px;
    overflow: hidden;

    &-wrapper {
      aspect-ratio: 247 / 115;
      width: 100%;
      height: auto
    }

    &-img {
      width: 100%;
      height: auto;
      display: block;
    }

    &-name {
      display: flex;
      gap: 8px;
      padding: 12px;
      align-items: center;
      justify-content: center;
      border-top: $file-border-style;
      width: 100%;
    }

    &-text {
      @include text-type(400, 14px, 20px);
    }

    &-sign {
      color: var(--colorPaletteGreenBackground3);
      padding: 12px;
      width: 100%;
      border-top: $file-border-style;
      border-bottom: $file-border-style;
      border-left: 0;
      border-right: 0;
    }

    &-signature-preview {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: $file-border-style;
      padding: 12px;
      a {
        color: var(--colorPaletteRoyalBlueBorderActive);
        @include text-type(600, 14px, 18px);
      }
    }

    &-refuse {
      color: var(--colorPaletteDarkOrangeBackground3);
      width: 50%;
      border: 0;
      padding: 12px;
    }

    button {
      background: none;
      cursor: pointer;
      @include text-type(600, 14px, 18px);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dashboard-sign-intro__file {
    .dashboard-sign-intro__file-text {
      @include ellipsis;
    }
  }
}
